import React, { FC } from 'react'

import { SvgIconProps, useTheme } from '@material-ui/core'

export const EventActivityIcon: FC<SvgIconProps> = (props) => {
  const theme = useTheme()
  const iconColor = props.style?.color || theme.colors.activityTypes.event

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0044 17C26.0044 15.0222 25.4179 13.0888 24.3191 11.4443C23.2203 9.79981 21.6585 8.51808 19.8312 7.7612C18.004 7.00433 15.9933 6.8063 14.0535 7.19215C12.1137 7.578 10.3319 8.53041 8.93334 9.92893C7.53481 11.3275 6.5824 13.1093 6.19655 15.0491C5.8107 16.9889 6.00873 18.9996 6.76561 20.8268C7.52249 22.6541 8.80421 24.2159 10.4487 25.3147C12.0932 26.4135 14.0266 27 16.0044 27C18.6566 27 21.2001 25.9464 23.0755 24.0711C24.9508 22.1957 26.0044 19.6522 26.0044 17ZM8.88253 5.70062C8.35777 5.25158 7.69069 5.0033 7.00003 5L6.82565 5.005C5.22878 5.10125 4.00003 6.5 4.00441 8.1875C4.00441 9.01313 4.29566 9.39812 4.68441 9.88937C4.70855 9.92094 4.73905 9.9471 4.77393 9.96615C4.80881 9.9852 4.8473 9.99674 4.88691 10H4.94191C4.97287 9.99945 5.0033 9.99177 5.03083 9.97758C5.05836 9.96338 5.08225 9.94304 5.10066 9.91813L8.89878 6.1875C8.93121 6.1547 8.95655 6.11558 8.97321 6.07257C8.98988 6.02956 8.99752 5.98359 8.99565 5.9375C8.99408 5.89236 8.98322 5.84804 8.96376 5.80728C8.9443 5.76653 8.91664 5.73022 8.88253 5.70062V5.70062ZM23.1175 5.70062C23.6423 5.25158 24.3094 5.0033 25 5L25.1744 5.005C26.7713 5.10125 28 6.5 27.9956 8.1875C27.9956 9.01313 27.7044 9.39812 27.3156 9.88937C27.2914 9.92083 27.2609 9.94686 27.226 9.96581C27.1912 9.98475 27.1527 9.99619 27.1131 9.99938H27.0581C27.0272 9.99882 26.9967 9.99115 26.9692 9.97695C26.9417 9.96275 26.9178 9.94241 26.8994 9.9175L23.1013 6.1875C23.0688 6.1547 23.0435 6.11558 23.0268 6.07257C23.0102 6.02956 23.0025 5.98359 23.0044 5.9375C23.006 5.89236 23.0168 5.84804 23.0363 5.80728C23.0558 5.76653 23.0834 5.73022 23.1175 5.70062V5.70062Z"
        stroke={iconColor}
        stroke-width="1.3"
        stroke-miterlimit="10"
      />
      <path
        d="M16.0044 10H16.6544C16.6544 9.71744 16.4718 9.46724 16.2028 9.38101C15.9337 9.29478 15.6397 9.39226 15.4755 9.6222L16.0044 10ZM16.0044 17V17.65C16.3634 17.65 16.6544 17.359 16.6544 17H16.0044ZM11.0044 17L10.4755 16.6222C10.3339 16.8203 10.315 17.0809 10.4264 17.2974C10.5378 17.5139 10.7609 17.65 11.0044 17.65V17ZM25.5448 27.4596C25.7986 27.7135 26.2102 27.7135 26.464 27.4596C26.7179 27.2058 26.7179 26.7942 26.464 26.5404L25.5448 27.4596ZM23.964 24.0404C23.7102 23.7865 23.2986 23.7865 23.0448 24.0404C22.7909 24.2942 22.7909 24.7058 23.0448 24.9596L23.964 24.0404ZM5.54478 26.5404C5.29093 26.7942 5.29093 27.2058 5.54478 27.4596C5.79862 27.7135 6.21017 27.7135 6.46401 27.4596L5.54478 26.5404ZM8.96401 24.9596C9.21786 24.7058 9.21786 24.2942 8.96401 24.0404C8.71017 23.7865 8.29862 23.7865 8.04478 24.0404L8.96401 24.9596ZM15.3544 10V17H16.6544V10H15.3544ZM16.0044 16.35H11.0044V17.65H16.0044V16.35ZM11.5333 17.3778L16.5333 10.3778L15.4755 9.6222L10.4755 16.6222L11.5333 17.3778ZM26.464 26.5404L23.964 24.0404L23.0448 24.9596L25.5448 27.4596L26.464 26.5404ZM23.0448 24.9596L25.5448 27.4596L26.464 26.5404L23.964 24.0404L23.0448 24.9596ZM6.46401 27.4596L8.96401 24.9596L8.04478 24.0404L5.54478 26.5404L6.46401 27.4596Z"
        fill={iconColor}
      />
    </svg>
  )
}
